import React, { useEffect } from 'react';
import './../../styles/bulk_data_styles/bulk_data_root_component.css'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Skeletons from '../shared_components/skeletons';
import ListPartners from './ListPartners';
import CreatePartner from './CreatePartner';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import { PartnerDetails, ResetPasswordRequest } from '../../types/bulk_data_types/Partners';
import ResetPassword from './ResetPassword';
import ListProducts from './ListProducts';
import ListSlogans from './ListSlogans';
import CreateSlogan from './CreateSlogan';
import CreateProduct from './CreateProduct';
import { useBulkDataCache } from './BulkDataCacheProvider';
import BulkDataSearchComponent from './BulkDataSearchComponent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { hasRoleAssigned } from '../../utility/appRoles';

const pages = [{name: 'PARTNERS', active: true}, {name:'PRODUCTS', active:false}, {name:'SLOGANS', active:false}];
const partnerOptions = ['Create Partner'];
const productsOptions = ['Create Product'];
const smsOptions = ['Create Slogan'];

const BulkDataRootComponent:React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedPartner, setSelectedPartner] = React.useState<PartnerDetails | null>(null);  
  const [passwordResetRequest, setPasswordResetRequest] = React.useState<ResetPasswordRequest | null>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [actionSuccessMessage, setActionSuccessMessage] = React.useState<string>('');
  const [openModalElement, setOpenModalElement] = React.useState<JSX.Element>(<Skeletons />);
  const dt = useBulkDataCache();

  const handleListProductsOptionNeedingContentViewChange = (partnerDetails: PartnerDetails | null) => {
    if (partnerDetails) {
      dt.setCache("activePartner", partnerDetails);
      pages.forEach(page => {
        page.name === "PRODUCTS" ? page.active = true : page.active = false;
      });
      setContentView(<ListProducts         
        showActionOnModal={setOpenModalElement}
        setModalOpen={setOpenModal} 
        setActionSuccessMessage={setActionSuccessMessage}
        />);
      setActionOptions(productsOptions);
      setSelectedPartner(partnerDetails);      
    }else{
      setSelectedPartner(null)
    }
  }

  const handleListSlogansOptionNeedingContentViewChange = async (partnerDetails: PartnerDetails | null) => {
    if (partnerDetails) {
      dt.setCache("activePartner", partnerDetails);
      pages.forEach(page => {
        page.name === "SLOGANS" ? page.active = true : page.active = false;
      });
      setContentView(<ListSlogans        
        showActionOnModal={setOpenModalElement}
        setModalOpen={setOpenModal} 
        setActionSuccessMessage={setActionSuccessMessage} />);
      setSelectedPartner(partnerDetails);
      setActionOptions(smsOptions);
      dt.setCache("activePartner", partnerDetails);
    }else{
      setSelectedPartner(null)
    }
  }

  const [contentView, setContentView] = React.useState<JSX.Element>(<ListPartners onResetPassword={setPasswordResetRequest} 
    onSelectListProductsOption={handleListProductsOptionNeedingContentViewChange} 
    onSelectListSlogansOption={handleListSlogansOptionNeedingContentViewChange}/>); 
  const isActionsElementOpen = Boolean(anchorEl);
  const [actionOptions, setActionOptions] = React.useState<string[]>(partnerOptions);
  
  const handleActionOptionsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleActionOptionsSelection = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, option: string) => {
    handleActionOptionsClose();
    switch (option) {
      case "Create Partner":
        setOpenModal(true);
        setOpenModalElement(<CreatePartner setModalOpen={setOpenModal} setActionSuccessMessage={setActionSuccessMessage} />);        
        break;
      case "Create Product":
        setOpenModal(true);
        setOpenModalElement(<CreateProduct 
          setModalOpen={setOpenModal}
          setActionSuccessMessage={setActionSuccessMessage} />);
        break;
      case "Create Slogan":
        setOpenModal(true);
        setOpenModalElement(<CreateSlogan 
          partnerName={selectedPartner ? selectedPartner.username : ''} 
          setModalOpen={setOpenModal} 
          setActionSuccessMessage={setActionSuccessMessage} />);
        break;
      default:
        setContentView(<Skeletons />);
        break;
    }
  }

  const handleBulkDataNavClick = (e: React.MouseEvent<HTMLButtonElement>, action: { name: string; active: boolean; }) => {
      e.preventDefault();
      switch (action.name) {
        case "PARTNERS":
          setContentView(<ListPartners onResetPassword={setPasswordResetRequest} 
            onSelectListProductsOption={handleListProductsOptionNeedingContentViewChange} 
            onSelectListSlogansOption={handleListSlogansOptionNeedingContentViewChange}/>);
          setActionOptions(partnerOptions);
          break;
        case "PRODUCTS":
          setContentView(<ListProducts
            showActionOnModal={setOpenModalElement}
            setModalOpen={setOpenModal} 
            setActionSuccessMessage={setActionSuccessMessage}
          />);
          setActionOptions(productsOptions);
          break;
        case "SLOGANS":          
          setContentView(<ListSlogans             
            showActionOnModal={setOpenModalElement}
            setModalOpen={setOpenModal} 
            setActionSuccessMessage={setActionSuccessMessage} />);
          setActionOptions(smsOptions);
          break;
        default:
          setContentView(<Skeletons />);
          break;
      }
      pages.forEach(page => {
        page.name === action.name ? page.active = true : page.active = false;
      });
  }

  
  useEffect(() => {
    
    const timer = setTimeout(() => {
      setActionSuccessMessage("");
    }, 5000);    
    return () => clearTimeout(timer);
  }, [actionSuccessMessage, setActionSuccessMessage]);

  useEffect(() => {
    if (passwordResetRequest) {
      setOpenModal(true);
      passwordResetRequest.setModalOpen = setOpenModal;
      passwordResetRequest.setActionSuccessMessage = setActionSuccessMessage;
      setOpenModalElement(<ResetPassword CREATED_BY={passwordResetRequest.CREATED_BY} USERNAME={passwordResetRequest.USERNAME}
      ACCOUNT_EMAIL={passwordResetRequest.ACCOUNT_EMAIL} setModalOpen={setOpenModal} setActionSuccessMessage={setActionSuccessMessage}
      setPasswordRequest={setPasswordResetRequest} />)
}}, [passwordResetRequest, setPasswordResetRequest])

  return (hasRoleAssigned('BulkData.Write') || hasRoleAssigned('BulkData.Read')) ?
    <>
      <div className='bulk-data-nav'>
        <div className='bulk-data-nav-title'>
          <img src="./../../../bulk_data_logo.png" alt="Bulk Data Logo" />
        </div>
        <div className='bulk-data-nav-items'>
          {pages.map((page) => {
            return (                  
              <Button key={page.name} 
                      sx={{ minWidth: 100, color: page.active ? "#FFFFFF" : "#3AA335" }} 
                      defaultValue={page.name}
                      onClick={(e) => handleBulkDataNavClick(e, page)}
                      variant={page.active ? 'contained' : 'text'}
                      color='success'
                      >{page.name}</Button>
            )
          })}
        </div>            
        <div className='bulk_data_search_component'>
          <BulkDataSearchComponent></BulkDataSearchComponent>
          <Button
            id="bk-actions-button"
            aria-controls={isActionsElementOpen ? 'bk-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isActionsElementOpen ? 'true' : undefined}
            variant="outlined"
            disableElevation
            onClick={handleActionOptionsButtonClick}
            endIcon={<KeyboardArrowDownIcon />}
            color="success"
            sx={{ color: "#3AA335" }}
          >
            ACTIONS
          </Button>
            <Menu
              id="bk-actions-menu"
              MenuListProps={{
                'aria-labelledby': 'bk-actions-button',
              }}
              anchorEl={anchorEl}
              open={isActionsElementOpen}
              onClose={handleActionOptionsClose}
            >
              {actionOptions.map((option) => {
                  return (
                    <MenuItem key={option} onClick={e => handleActionOptionsSelection(e, option)} disableRipple sx={{ color: "#3AA335" }}>
                      {option}
                    </MenuItem>
                  )
              })}                                    
            </Menu>
          </div>
      </div>
      <div className='bulk-data-content-container'>
        {actionSuccessMessage.length > 0 ?  <Alert severity="success">{actionSuccessMessage}</Alert>: null}
        {contentView}
        <Modal
            sx={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", overflow: "scroll"}}
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            {hasRoleAssigned('BulkData.Write') ? openModalElement : 
              <Box sx={{ color: '#808080', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', width: 400, height:200, backgroundColor: '#ffffff'}}>                    
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  You do not have permissions to perform this action
                </Typography>
            </Box>
            }
        </Modal>            
      </div>
    </> : <div className='bulk_data_permissions_error'>You do not have access to this site</div>
}

export default BulkDataRootComponent