import React, { useCallback, useEffect, useState } from 'react';
import { ListProductProps, ProductDetails, Products } from '../../types/bulk_data_types/Products';
import axios from 'axios';
import Loader from '../shared_components/Loader';
import { Dropdown, Popover, Table, Whisper } from 'rsuite';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import IconButton from 'rsuite/IconButton';
import "./../../styles/bulk_data_styles/list_product.css";
import MoreIcon from '@rsuite/icons/legacy/More';
import { PositionChildProps } from 'rsuite/esm/Picker';
import UpdateProduct from './UpdateProduct';
import { useBulkDataCache } from './BulkDataCacheProvider';
import { PartnerDetails } from '../../types/bulk_data_types/Partners';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';
import GenericTablePagination from '../shared_components/GenericTablePagination';

const ListProducts:React.FC<ListProductProps> = (listProductProps : ListProductProps) => {
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [reloadTable, setReloadTable] = useState<boolean>(true);
    const [listProductProp] = useState<ListProductProps>(listProductProps);
    const [products, setProducts] = useState<Products>({ content: [], pageable: { pageNumber: 0, pageSize: 10}, totalElements: 0});
    const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
    const [activePartner, setActivePartner] = React.useState<string | null>(null);
    const { Column, HeaderCell, Cell } = Table;
    const rowKey = 'productName';
    const dt = useBulkDataCache();

    const renderRowExpanded:React.FC<ProductDetails | undefined> = (rowData) => {
        return rowData ? (
          <div className='expanded-row-container'>
            <div className='expanded-row-data'>
                <span><b>SMS:</b></span>
                <span>{rowData.smsUnits} SMSs</span>
            </div>
            <div className='expanded-row-data'>
                <span><b>Data:</b></span>
                <span>{rowData.dataUnits} MBs</span>
            </div>
            <div className='expanded-row-data'>
                <span><b>Minutes:</b></span>
                <span>{rowData.minutesUnits} Minutes</span>
            </div>
            <div className='expanded-row-data'>
                <span><b>Airtime:</b></span>
                <span>{rowData.airtimeUnits} Ksh</span>
            </div>
          </div>
        ) : null;
      };

    const getProducts = useCallback(() => {
        const partner = dt.getCache("activePartner") as PartnerDetails;
        
        if (partner) {
            setShowLoader(true);           
            getBulkDataAuthentication().then(
                response => {
                  const access_token = typeof response === 'string' ? response : null;
                  if (access_token) {
                    axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/listproductsrequest?page=${pageNumber}&size=${pageSize}`, {
                        ListProductRequest: {
                            USERNAME: partner.username,
                        }
                    }, 
                    {
                        headers: {
                            'x-source-system': 'bulk-data-portal',
                            'Authorization': `Bearer ${access_token}`,
                            'Content-Type': 'application/json'
                        }})
                    .then(response => {
                        setProducts(response.data as Products);
                        setShowLoader(false);
                    })
                    .catch(error => {
                        console.log(error);                
                    }).finally(() => {
                        setShowLoader(false);
                    })
                  } 
                  else {
                    setShowLoader(false);
                  }
                }
              ).catch(error => {
                console.log(error);
                setShowLoader(false);
              })
        }        
    }, [dt, pageNumber, pageSize])

    useEffect(() => {        
      const interval = setInterval(() => {
        const partner = dt.getCache("activePartner") as PartnerDetails;            
        if (partner) {
            if (partner.username !== activePartner) setPageNumber(0);
            setActivePartner(partner.username);
        } else {
            setPageNumber(0);
            setActivePartner(null);
        }      
        }, 500);
        return () => clearInterval(interval);
      }, [dt, activePartner])

    useEffect(() => {
        if (activePartner) {
            getProducts();
        }
    }, [activePartner, setActivePartner, getProducts, pageNumber, pageSize])

    useEffect(() => {
        if (reloadTable) {
            getProducts();
            setReloadTable(false);
        }
    }, [reloadTable, getProducts])

    useEffect(() => {        
        const interval = setInterval(() => {
        const reload = dt.getCache("reloadProductsList") as string;          
        if (reload === "true") {
            getProducts();
            dt.setCache("reloadProductsList", "false");
        }    
        }, 500);
        return () => clearInterval(interval);
    }, [dt, getProducts])    

    const handleExpanded = (rowData: ProductDetails) => {
        let open = false;
        const nextExpandedRowKeys = [];
    
        expandedRowKeys.forEach(key => {
          if (key === rowData[rowKey]) {
            open = true;
          } else {
            nextExpandedRowKeys.push(key);
          }
        });
    
        if (!open) {
          nextExpandedRowKeys.push(rowData[rowKey]);
        }
    
        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const renderMenu = (e: PositionChildProps & 
        Pick<React.HTMLAttributes<HTMLElement>, "id" | "onMouseEnter" | "onMouseLeave"> &
         { onClose: (delay?: number | undefined) => void | NodeJS.Timeout; }, 
         ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | 
                null | undefined,
         rowData: ProductDetails) => {

        const handleSelect = (eventKey: string | undefined) => {       
          console.log(eventKey);
          if (eventKey && eventKey == '1') {
            listProductProp.setModalOpen(true);
            listProductProp.showActionOnModal(<UpdateProduct 
                product={rowData}
                setReload={setReloadTable}
                setModalOpen={listProductProp.setModalOpen} 
                setActionSuccessMessage={listProductProp.setActionSuccessMessage} />)
          } else {
            console.log(eventKey);
          }
          
          e.onClose();
        };
        
        return (
          <Popover ref={ref} className={e.className}  full>
            <Dropdown.Menu onSelect={evtKey => handleSelect(evtKey)}>
              <Dropdown.Item eventKey={1}>Top Up</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
    };

    
    return (activePartner ? (showLoader ? <Loader /> : (
        products && products.content?.length > 0 ? 
           (
           <>
           <Table
                shouldUpdateScroll={false} // Prevent the scrollbar from scrolling to the top after the table content area height changes.
                autoHeight={true}
                height={500}
                data={products.content}
                rowKey={rowKey}
                expandedRowKeys={expandedRowKeys}
                renderRowExpanded={renderRowExpanded}
                >
                <Column width={40} align="center" resizable>
                    <HeaderCell>#</HeaderCell>
                    <Cell style={{ padding: 5}}>{rowData => 
                        <IconButton
                        appearance="subtle"
                        onClick={() => {
                            handleExpanded(rowData as ProductDetails);
                        }}
                        icon={
                            expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                            <CollaspedOutlineIcon />
                            ) : (
                            <ExpandOutlineIcon />
                            )
                        }
                        />
                    }
                    </Cell>
                </Column>

                <Column width={150} resizable>
                    <HeaderCell>Package Name</HeaderCell>
                    <Cell dataKey="productName" />
                </Column>

                <Column width={150} resizable>
                    <HeaderCell>Partner Name</HeaderCell>
                    <Cell dataKey="partnerName" />
                </Column>

                <Column width={100} resizable>
                    <HeaderCell>No. of Units</HeaderCell>
                    <Cell dataKey="packageSize" />
                </Column>

                <Column width={130} resizable>
                    <HeaderCell>Units Balance</HeaderCell>
                    <Cell dataKey="packageBalance" />
                </Column>

                <Column width={200} resizable>
                    <HeaderCell>Created By</HeaderCell>
                    <Cell dataKey="createdBy" />
                </Column>
                <Column width={200} resizable>
                    <HeaderCell>Created At</HeaderCell>
                    <Cell dataKey="createdAt" />
                </Column>
                <Column width={80}>
                    <HeaderCell>Active</HeaderCell>
                    <Cell>{rowData => (!(rowData.inActive)).toString()}</Cell>
                </Column>
                
               <Column flexGrow={1} align="left">
                    <HeaderCell style={{fontWeight: "bolder", width: "100px"}}>Actions</HeaderCell>

                    <Cell className="link-group" style={{fontWeight: "bolder", display:"flex", alignItems: "center", alignContent: "center"}}>
                        {rowData => <Whisper placement="autoVerticalStart" trigger="click" speaker={(e, ref) => renderMenu(e, ref, rowData as ProductDetails)}>
                            <IconButton appearance="subtle" icon={<MoreIcon />}/>
                        </Whisper>
                        }
                    </Cell>
                </Column>
            </Table>

            { products.totalElements > pageSize ? (
           <GenericTablePagination 
               pageChangeHandler={ setPageNumber } 
               pageSizeHandler={setPageSize} 
               totalRows={products.totalElements}
               pageNumber={pageNumber}
               rowsPerPage={pageSize} />) : null
           }
           </>
           ) : <span>No Products Loaded For Selected Partner</span>
       )): <span>Select a partner to view partner specific products</span>)
}

export default ListProducts