import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import "./../../styles/bulk_data_styles/create_partner.css"
import Button from '@mui/material/Button';
import axios from 'axios';
import { ErrorDetails } from '../../types/ErrorInfo';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { AddPartnerFormOptions, CreatePartnerModalProps } from '../../types/bulk_data_types/Partners';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';
import { useBulkDataCache } from './BulkDataCacheProvider';

const CreatePartner:React.FC<CreatePartnerModalProps> = (createPartnerModalProps: CreatePartnerModalProps) => {
    const [accountEmail, setAccountEmail] = useState<string>('');
    const [accountUsername, setAccountUsername] = useState<string>('');
    const [accountPartnerManagerNumber, setAccountPartnerManagerNumber] = useState<string>('');
    const [accountManagerNumber, setAccountManagerNumber] = useState<string>('');
    const [accountProductManagerNumber, setAccountProductManagerNumber] = useState<string>('');
    const userEmail = localStorage.getItem('userEmail');
    const [accountCreatedBy] = useState<string>(userEmail ? userEmail : '');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isCreatePartnerLoading, setIsCreatePartnerLoading] = useState<boolean>(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);
    const dt = useBulkDataCache();

    const addPartnerOptions: AddPartnerFormOptions[] = [
        {
            label: "Account Email",
            id: "cp-account-email",
            onChange: (e) => setAccountEmail(e.target.value)
        },
        {
            label: "Account Username",
            id: "cp-account-username",
            onChange: (e) => setAccountUsername(e.target.value)
        },
        {
            label: "Account Manager No.",
            id: "cp-account-mgrNo",
            onChange: (e) => setAccountManagerNumber(e.target.value)
        },
        {
            label: "Account Product No.",
            id: "cp-account-prdNo",
            onChange: (e) => setAccountProductManagerNumber(e.target.value)
        },
        {
            label: "Account Partner No.",
            id: "cp-account-ptrNo",
            onChange: (e) => setAccountPartnerManagerNumber(e.target.value)
        },
    ];

    const addPartner = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsCreatePartnerLoading(true);
        setIsCancelDisabled(true);        
        //console.log(process.env.REACT_APP_LDAP_URL);       
        
        getBulkDataAuthentication().then(
            response => {
                const access_token = typeof response === 'string' ? response : null;
                if (access_token) {
                    axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/createuserrequest`, {
                        CreateUserRequest: {
                            CREATED_BY: accountCreatedBy,
                            ACCOUNT_MANAGER_NUMBER: accountManagerNumber,
                            PRODUCT_MANAGER_NUMBER: accountProductManagerNumber,
                            ACCOUNT_PARTNER_NUMBER: accountPartnerManagerNumber,
                            USERNAME: accountUsername,
                            ACCOUNT_EMAIL: accountEmail
                          }
                    }, 
                    {
                        headers: {
                            'Authorization': `Bearer ${access_token}`,
                            'x-source-system': 'bulk-data-portal',
                            'Content-Type': 'application/json'
                        }})
                    .then(response => {
                        if (response.data.Status == '1999') {
                            setErrorMessage(response.data.Desc)
                        } else {
                            createPartnerModalProps.setModalOpen(false);
                            createPartnerModalProps.setActionSuccessMessage("Partner successfully added.")
                            dt.setCache("reloadPartnerDetailsTable", "true");
                        }
                      })
                    .catch(error => {
                        const errMessage = (error as ErrorDetails).message;
                        setErrorMessage(errMessage == null ? "An error occurred" : errMessage);
                      })
                    .finally(
                        () => {
                            setIsCreatePartnerLoading(false);
                            setIsCancelDisabled(false);
                      });
                } else {
                    setIsCreatePartnerLoading(false);
                    setIsCancelDisabled(false);
                }
            }
            ).catch(error => {
                console.log(error);
                setIsCreatePartnerLoading(false);
                setIsCancelDisabled(false);
            })
    }

    useEffect(() => {    
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 8000);    
        return () => clearTimeout(timer);
      }, [errorMessage, setErrorMessage])

    return (
        <div className='modal-container'>
            <div className='cp-modal-title'>
                <span>ADD PARTNER ACCOUNT</span>
            </div>
            <hr className='cp-modal-hr' />
            {errorMessage.length > 0 ?  <Alert severity="warning">{errorMessage}</Alert>: null}
            <form className='modal-form' onSubmit={(e) => addPartner(e)}>
                <div className='modal-content-form-input'>
                    {addPartnerOptions.map((option) => {
                        return <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                            onChange={option.onChange}
                        />
                    })}
                </div>
                <hr className='cp-modal-hr' />
                <div className='modal-content-form-submit'>
                    <LoadingButton loading={isCreatePartnerLoading} loadingIndicator={"CREATING..."} type='submit' variant="outlined" color="success" sx={{width:"200px"}}>
                        CREATE
                    </LoadingButton>
                    <Button disabled={isCancelDisabled} variant="outlined" color="error" sx={{width:"200px"}} onClick={() => createPartnerModalProps.setModalOpen(false)}>
                        CANCEL
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default CreatePartner