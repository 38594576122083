import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ErrorDetails } from '../../types/ErrorInfo';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { AddProductProps } from '../../types/bulk_data_types/Products';
import { useBulkDataCache } from './BulkDataCacheProvider';
import { PartnerDetails } from '../../types/bulk_data_types/Partners';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CreateProduct:React.FC<AddProductProps> = (addProductProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);
    const [addProductProp] = useState<AddProductProps>(addProductProps);
    const [minutesCode] = useState<string>('');
    const [minutesUnits] = useState<number>(0);
    const [airtimeCode] = useState<string>('');
    const [airtimeUnits] = useState<number>(0);
    const [smsCode] = useState<string>('');
    const [smsUnits] = useState<number>(0);
    const [dataCode] = useState<string>('2564');
    const [dataUnits, setDataUnits] = useState<number>(10);
    const [smsMessage, setSmsMessage] = useState<string>("Dear customer, you have received FREE Safaricom Promo Bundles for your engagement with our partner products and services.");
    const [expiryPeriod, setExpiryPeriod] = useState<number>(1);
    const [productName, setProductName] = useState<string>('');
    const [notifiedAt] = useState<number>(0);
    const [packageSize, setPackageSize] = useState<number>(0);
    const [packageBalance, setPackageBalance] = useState<number>(0);
    const userEmail = localStorage.getItem('userEmail');
    const [createdBy] = useState<string>(userEmail ? userEmail : '');
    const dt = useBulkDataCache();
    const [activePartner] = useState<PartnerDetails | null>((dt.getCache("activePartner") as PartnerDetails) ? 
            (dt.getCache("activePartner") as PartnerDetails) : null);

    const handlePackageSizeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const size = Number(e.target.value);
        if (isNaN(size)) {
            setErrorMessage("Invalid number on package size")
        } else {
            setPackageSize(size);
            setPackageBalance(size);
        }
    }

    const handleExpiryPeriodChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const size = Number(e.target.value);
        if (isNaN(size)) {
            setErrorMessage("Invalid number on expiry period")
        } else {
            setProductName("SAFPROMO_" + dataUnits + "MBS_VALID_" + e.target.value + "_DAYS")
            setExpiryPeriod(Number(e.target.value));
        }
    }

    const productDetails = [
    {
        id: "sms-message",
        label: "SMS Message",
        type: "string",
        mutable: true,
        defaultValue: smsMessage,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSmsMessage(e.target.value)
    },
    {
        id: "expiry-period",
        label: "Validity Period In Days",
        type: "number",
        mutable: true,
        defaultValue: expiryPeriod,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            isNaN(Number(e.target.value)) ? setErrorMessage("Invalid number on data units amount") : handleExpiryPeriodChange(e)}
    },
    {
        id: "product-name",
        label: "Product Name",
        type: "string",
        mutable: false,
        defaultValue: productName,
    },
    {
        id: "user-email",
        label: "User Email",
        type: "string",
        mutable: false,
        defaultValue: activePartner?.accountEmail
    },
    {
        id: "username",
        label: "Username",
        type: "string",
        mutable: false,
        defaultValue: activePartner?.username
    },
    {
        id: "package-size",
        label: "No. of Units",
        type: "number",
        mutable: true,
        defaultValue: packageSize.toString(),
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handlePackageSizeChange(e)
    },
];

    const productPackages = [
        {
            label: "10 MBs",
            value: 10
        },
        {
            label: "20 MBs",
            value: 20
        },
        {
            label: "50 MBs",
            value: 50
        },
        {
            label: "75 MBs",
            value: 75
        },
        {
            label: "100 MBs",
            value: 100
        },        
        {
            label: "150 MBs",
            value: 150
        },
        {
            label: "200 MBs",
            value: 200
        },
        {
            label: "250 MBs",
            value: 250
        },
        {
            label: "500 MBs",
            value: 500
        },
        {
            label: "1000 MBs",
            value: 1000
        },
        {
            label: "2000 MBs",
            value: 2000
        },
        {
            label: "3000 MBs",
            value: 3000
        },
        {
            label: "4000 MBs",
            value: 4000
        },
        {
            label: "5000 MBs",
            value: 5000
        }
    ];

    const createProduct = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setIsCancelDisabled(true);
        
        getBulkDataAuthentication().then(
            response => {
                const access_token = typeof response === 'string' ? response : null;
                if (access_token) {
                    axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/createproductrequest`, {
                        CreateProductRequest:{
                            CREATED_BY: createdBy,
                            MINUTES_CODE: minutesCode,
                            AIRTIME_UNITS: airtimeUnits,
                            AIRTIME_CODE: airtimeCode,
                            SMS_UNITS: smsUnits,
                            SMS_CODE: smsCode,
                            DATA_UNITS: dataUnits,
                            DATA_CODE: dataCode,
                            SEND_SMS_MESSAGE: smsMessage,
                            EXPIRY_PERIOD: expiryPeriod,
                            PRODUCT_NAME: productName,
                            PRODUCT_CODE: null,
                            NOTIFIED_AT: notifiedAt,
                            MINUTES_UNITS: minutesUnits,
                            USEREMAIL: activePartner?.accountEmail,
                            USERNAME: activePartner?.username,
                            PACKAGE_SIZE: packageSize,
                            PACKAGE_BALANCE: packageBalance,
                            ORDER_NUMBER: null
                        }
                    }, 
                    {
                        headers: {
                            'x-source-system': 'bulk-data-portal',
                            'Authorization': `Bearer ${access_token}`,
                            'Content-Type': 'application/json'
                        }})
                    .then(response => {
                        if (response.data.Status == '1000') {
                            addProductProp.setModalOpen(false);
                            addProductProp.setActionSuccessMessage("Product successfully created.");
                            dt.setCache("reloadProductsList", "true");
                        } else {                
                            setErrorMessage(response.data.Desc);
                        }
                      })
                    .catch(error => {
                        const errMessage = (error as ErrorDetails).message;
                        setErrorMessage(errMessage == null ? "An error occurred" : errMessage);
                        })
                    .finally(
                        () => {
                            setIsLoading(false);
                            setIsCancelDisabled(false);
                        }
                    );
                } else {                    
                    setIsLoading(false);
                    setIsCancelDisabled(false);
                }
            }
            ).catch(error => {
                console.log(error);                
                setIsLoading(false);
                setIsCancelDisabled(false);
            })
    }
    
    const closeModal = () => {
        addProductProp.setModalOpen(false);
    }

    useEffect(() => {    
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 8000);    
        return () => clearTimeout(timer);
      }, [errorMessage, setErrorMessage])

    return (
        <div className='modal-container'>
            {activePartner ? (
            <>
            <div className='cp-modal-title'>
                <span>CREATE PRODUCT</span>
            </div>
            <hr className='cp-modal-hr' />
            {errorMessage.length > 0 ?  <Alert severity="warning">{errorMessage}</Alert>: null}
            <form className='modal-form' onSubmit={(e) => createProduct(e)}>
                <div className='modal-content-form-input'>
                <FormControl sx={{ marginTop: '10px', marginBottom: '10px' }} size="small" color="success" required fullWidth>
                    <InputLabel id="demo-simple-select-autowidth-label">Data in MBs</InputLabel>
                    <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={dataUnits}
                    onChange={(value) => {
                        setDataUnits(Number(value.target.value))
                        setProductName("SAFPROMO_" + value.target.value + "MBS_VALID_" + expiryPeriod + "_DAYS")
                    }}
                    autoWidth
                    size="small"                    
                    label="Data in MBs"
                    color="success"
                    >
                        {productPackages.map((option) => {
                            return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                    {productDetails.map((option) => {
                        return option.type == "number" ? 
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            type="number"
                            value={option.defaultValue}                            
                            onChange={e => option.onChange ? option.onChange(e) : undefined}
                            sx={{ marginTop: '10px', marginBottom: '10px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                        />                        
                        :
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            value={option.defaultValue}
                            onChange={e => option.onChange ? option.onChange(e) : undefined}
                            sx={{ marginTop: '10px', marginBottom: '10px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                            disabled={!(option.mutable)}
                        />
                    })}
                </div>
                <hr className='cp-modal-hr' />
                <div className='modal-content-form-submit'>
                    <LoadingButton loading={isLoading} loadingIndicator="CREATING..." type='submit' variant="outlined" color="success" sx={{width:"200px"}}>
                        CREATE
                    </LoadingButton>
                    <Button disabled={isCancelDisabled} variant="outlined" color="error" sx={{width:"200px"}} onClick={() => closeModal()}>
                        CANCEL
                    </Button>
                </div>
            </form>
            </>
            ) : <span>Select Partner Before Creating Product</span>}
            
        </div>
    )
}

export default CreateProduct