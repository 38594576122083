import React from "react";
import { hasRoleAssigned } from "../../utility/appRoles";
import './../../styles/skiza_styles/skiza_root_component.css'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Skeletons from "../shared_components/skeletons";
import ListShortcuts from "./ListShortcuts";

const pages = [{name: '811', active: true}, {name:'812', active:false}, {name:'860', active:false}, {name:'875', active:false}];

const SkizaRootComponent: React.FC = () => {

    const [contentView, setContentView] = React.useState<JSX.Element>(<ListShortcuts shortcode={"811"} />);
    const [actionSuccessMessage] = React.useState<string>('');
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [openModalElement] = React.useState<JSX.Element>(<Skeletons />);


    const handleSkizaShortcutsNavClick = (e: React.MouseEvent<HTMLButtonElement>, action: { name: string; active: boolean; }) => {
        e.preventDefault();
        console.log(action.name);
        switch (action.name) {
          case "811":
            /*setContentView(<ListPartners onResetPassword={setPasswordResetRequest} 
              onSelectListProductsOption={handleListProductsOptionNeedingContentViewChange} 
              onSelectListSlogansOption={handleListSlogansOptionNeedingContentViewChange}/>);*/
            setContentView(<ListShortcuts shortcode={"811"} />);
            console.log('loaded 811');
            break;
          case "812":   
            setContentView(<ListShortcuts shortcode={'812'} />);
            console.log('loaded 812');         
            break;
          case "860":
            setContentView(<ListShortcuts shortcode={"860"} />);
            console.log('loaded 860');
            break;
          case "875":
            setContentView(<ListShortcuts shortcode={"875"} />);
            break;
          default:
            setContentView(<Skeletons />);
            break;
        }
        pages.forEach(page => {
          page.name === action.name ? page.active = true : page.active = false;
        });
    }

    return (hasRoleAssigned('Skiza.Write') || hasRoleAssigned('Skiza.Read')) ?
    <>
      <div className='skiza-shortcuts-nav'>
        <div className='skiza-nav-title'>
            <img src="./../../../skiza_logo.png" alt="Skiza Shortcuts Logo" />
            </div>
            <div className='bulk-data-nav-items'>
            {pages.map((page) => {
                return (                  
                <Button key={page.name} 
                        sx={{ minWidth: 100, color: page.active ? "#FFFFFF" : "#3AA335" }} 
                        defaultValue={page.name}
                        onClick={(e) => handleSkizaShortcutsNavClick(e, page)}
                        variant={page.active ? 'contained' : 'text'}
                        color='success'
                        >{page.name}</Button>
                )
            })}
            </div>
            <div className='skiza_search_component'>
                <Button
                    id="bk-actions-button"            
                    variant="outlined"
                    disableElevation
                    onClick={() => setOpenModal(true)}
                    color="success"
                    sx={{ color: "#3AA335" }}
                >
                    Update Shortcuts
                </Button>            
            </div>
      </div>      
      <div className='skiza-content-container'>
        {actionSuccessMessage.length > 0 ?  <Alert severity="success">{actionSuccessMessage}</Alert>: null}
        {contentView}
        <Modal
            sx={{display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", overflow: "scroll"}}
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            {hasRoleAssigned('Skiza.Write') ? openModalElement : 
              <Box sx={{ color: '#808080', display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', width: 400, height:200, backgroundColor: '#ffffff'}}>                    
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  You do not have permissions to perform this action
                </Typography>
            </Box>
            }
        </Modal>          
      </div>
    </> : <div className='skiza_permissions_error'>You do not have access to Skiza Shortcuts</div>
}

export default SkizaRootComponent;