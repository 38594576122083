import React, { useState } from "react"
import "./../styles/page_styles/dashboard_page_styles.css"
import Sidebar from "../components/shared_components/Sidebar"
import DashboardRootComponent from "../components/shared_components/DashboardRootComponent"
import { useMsal } from "@azure/msal-react"
import Button from "@mui/material/Button"
import { handleSignOut, hasRoleAssigned } from "../utility/appRoles"

const DashboardPage: React.FC = () => {
    const { instance, accounts } = useMsal();
    const [activeComponent, setActiveComponent] = useState<string>('dashboard');

    const getActiveComponent = () => {
        const currentActiveComponent = sessionStorage.getItem("activeComponent");
        if (currentActiveComponent) {
            return currentActiveComponent;
        }

        return activeComponent;
    }

    const setCurrentActiveComponent = (componentName: string) => {
        setActiveComponent(componentName);
        sessionStorage.setItem("activeComponent", componentName);
    }

    return  (  
        <>
        {hasRoleAssigned('BulkData.Read') || hasRoleAssigned('BulkData.Write') || hasRoleAssigned('Dashboard.Write')
        || hasRoleAssigned('Dashboard.Read') || hasRoleAssigned('Skiza.Write') || hasRoleAssigned('Skiza.Read') ?      
        <div className="dashboard-container">
            <Sidebar setActiveComponent={setCurrentActiveComponent} />
            <DashboardRootComponent componentName={getActiveComponent()} />
        </div> : 
        <div className='portal_permissions_error'>
            <span className="portal_permissions_error_message">You do not have access to this site.</span>
            <Button 
                sx={{ minWidth: 100, color: "#3AA335" }} 
                defaultValue={"Sign Out"}
                onClick={() => handleSignOut(instance, accounts)}
                variant={'outlined'}
                color='success'
                >Sign Out</Button>
        </div>
        }        
        </>       
    )
}

export default DashboardPage