import React from "react"
import './../../styles/shared_components_styles/loader.css'

const Loader: React.FC = () => {
    return (
        <div className="loader-container">
            <img src="/loader.gif" alt="Loading data"/>
            <span>Please wait</span>
        </div>
    )
}

export default Loader