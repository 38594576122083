import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";

/**
 * This function can be used anywhere in the app to greet the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const saveRoles = (accounts: AccountInfo[]) => {
    console.log(accounts);
    if (accounts && accounts[0]) {
        const claims = accounts[0].idTokenClaims?.DSAppRoles as string[];
        if (claims && claims.length > 0) {
            for (let i = 0; i < claims.length; i++) {
                sessionStorage.setItem(claims[i], 'true');
            }
        }
      }
}

export const hasRoleAssigned = (roleName: string) : boolean => {
    const isRolePresent = sessionStorage.getItem(roleName);
    return isRolePresent ? true : false;
}

export const handleSignOut = (instance: IPublicClientApplication, accounts: AccountInfo[]) => {
    localStorage.clear();
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
    };
    instance.logoutRedirect(logoutRequest);
}
