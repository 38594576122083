import React, { useCallback, useEffect, useState } from "react";
import { ListShortcutsProps } from "../../types/skiza_types/SkizaComponentProps";
import { Shortcuts } from "../../types/skiza_types/SkizaDataTypes";
import axios from "axios";
import { Table } from "rsuite";
import Loader from "../shared_components/Loader";
import GenericTablePagination from "../shared_components/GenericTablePagination";
import { getSkizaAuthentication } from "../auth/skiza_shortcuts_auth";

const { Column, HeaderCell, Cell } = Table;

const ListShortcuts: React.FC<ListShortcutsProps> = (listPartnersProps) => {
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [reloadTable, setReloadTable] = useState<boolean>(false);
    const [shortcuts, setShortcuts] = useState<Shortcuts>({ content: [], pageable: { pageNumber: 0, pageSize: 10}, totalElements: 0});
    const [filteredData, setFilteredData] = useState<Shortcuts | null>(null);    
    axios.defaults.withCredentials = false;

    const getPartners = useCallback(() => {
      setShowLoader(true);
      getSkizaAuthentication().then(
        response => {
          //console.log(typeof response)
          const access_token = typeof response === 'string' ? response : null;
          if (access_token) {
            axios.get(`${process.env.REACT_APP_SKIZA_API_URL}/mapping/search/findAllByUssdShortCode?shortcode=${listPartnersProps.shortcode}&page=${pageNumber}&size=${pageSize}&sort=id%2Casc`, {
              headers: {                
                'Authorization': `Bearer ${access_token}`
              },
            })
            .then(response => {
                if (response.status == 200) {
                    setShortcuts(response.data.body as Shortcuts);
                } else {
                    setShortcuts({ content: [], pageable: { pageNumber: 0, pageSize: 10}, totalElements: 0});
                }
                   
              setShowLoader(false);
            })
            .catch(error => {
              console.log(error);
              setShowLoader(false);
            });
          } 
          else {
            setShowLoader(false);
          }
        }
      ).catch(error => {
        console.log(error);
        setShowLoader(false);
      })    
    }, [pageNumber, pageSize, listPartnersProps.shortcode])

    useEffect(() => {
        getPartners()
    }, [getPartners, pageNumber, pageSize, listPartnersProps.shortcode])


    return filteredData ? (
        <Table
              height={500}
              autoHeight={true}            
              rowHeight={45}
              data={filteredData.content}
              >
              <Column width={200} align="left" resizable>
                  <HeaderCell>Mapping ID</HeaderCell>
                  <Cell dataKey="id" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120}>
                  <HeaderCell>Shortcode</HeaderCell>
                  <Cell dataKey="ussdShortCode" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120}>
                  <HeaderCell>Tone ID</HeaderCell>
                  <Cell dataKey="toneId" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120}>
                  <HeaderCell>Skiza Code</HeaderCell>
                  <Cell dataKey="toneCode" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120} resizable>
                  <HeaderCell>Tune Name</HeaderCell>
                  <Cell dataKey="toneName" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={100} resizable>
                  <HeaderCell>Artist</HeaderCell>
                  <Cell dataKey="singerName" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={150} resizable>
                  <HeaderCell>Content Provider</HeaderCell>
                  <Cell dataKey="contentProvider" style={{fontSize: 12}}/>
              </Column>              
          </Table>
      ) : (showLoader ? <Loader /> : (
       shortcuts && shortcuts.content?.length > 0 ? 
          (
          <>   
          <Table
              height={800}         
              rowHeight={45}
              data={shortcuts.content}
              >
              <Column width={200} align="left" resizable fixed>
                  <HeaderCell>Mapping ID</HeaderCell>
                  <Cell dataKey="id" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120}>
                  <HeaderCell>Shortcode</HeaderCell>
                  <Cell dataKey="ussdShortCode" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120}>
                  <HeaderCell>Tone ID</HeaderCell>
                  <Cell dataKey="toneId" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120}>
                  <HeaderCell>Skiza Code</HeaderCell>
                  <Cell dataKey="toneCode" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={120} resizable>
                  <HeaderCell>Tune Name</HeaderCell>
                  <Cell dataKey="toneName" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={100} resizable>
                  <HeaderCell>Artist</HeaderCell>
                  <Cell dataKey="singerName" style={{fontSize: 12}}/>
              </Column>
  
              <Column width={150} resizable>
                  <HeaderCell width={150}>Content Provider</HeaderCell>
                  <Cell dataKey="contentProvider" style={{fontSize: 12}}/>
              </Column>              
          </Table>
          
          <GenericTablePagination 
              pageChangeHandler={ setPageNumber } 
              pageSizeHandler={setPageSize} 
              totalRows={shortcuts.totalElements}
              pageNumber={pageNumber}
              rowsPerPage={pageSize} />
          </>
          ) : <span>An Error Occurred: No Data Loaded</span>)
      )
}

export default ListShortcuts;