import { ListPartnersProps, PartnerDetails, Partners, ResetPasswordRequest } from "../../types/bulk_data_types/Partners"
import React, { useCallback, useEffect, useState } from 'react'
import Loader from "../shared_components/Loader";
import axios from "axios";
import GenericTablePagination from "../shared_components/GenericTablePagination";
import { Table, Popover, Dropdown, Whisper, IconButton } from 'rsuite';
import "./../../styles/bulk_data_styles/list_partners.css";
import MoreIcon from '@rsuite/icons/legacy/More';
import { PositionChildProps } from "rsuite/esm/Picker";
import { useBulkDataCache } from "./BulkDataCacheProvider";
import { getBulkDataAuthentication } from "../auth/bulk_data_auth";

const { Column, HeaderCell, Cell } = Table;

const ListPartners: React.FC<ListPartnersProps> = (listPartnersProps) => {
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [partners, setPartners] = useState<Partners>({ content: [], pageable: { pageNumber: 0, pageSize: 10}, totalElements: 0});
    const [filteredData, setFilteredData] = useState<Partners | null>(null);
    const dt = useBulkDataCache();
    axios.defaults.withCredentials = false;

    const getPartners = useCallback(() => {
      setShowLoader(true);
      getBulkDataAuthentication().then(
        response => {
          //console.log(typeof response)
          const access_token = typeof response === 'string' ? response : null;
          if (access_token) {
            axios.get(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/listusers?page=${pageNumber}&size=${pageSize}`, {
              headers: {
                'Content-Type': 'application/json',
                'accept':'application/json',
                'x-source-system': 'bulk-data-portal',
                'Authorization': `Bearer ${access_token}`
              },
            })
            .then(response => {
              setPartners(response.data as Partners);     
              setShowLoader(false);
            })
            .catch(error => {
              console.log(error);
              setShowLoader(false);
            });
          } 
          else {
            setShowLoader(false);
          }
        }
      ).catch(error => {
        console.log(error);
        setShowLoader(false);
      })    
    }, [pageNumber, pageSize])

    const renderMenu = (e: PositionChildProps & 
        Pick<React.HTMLAttributes<HTMLElement>, "id" | "onMouseEnter" | "onMouseLeave"> &
         { onClose: (delay?: number | undefined) => void | NodeJS.Timeout; }, 
         ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | 
                null | undefined,
         rowData: PartnerDetails) => {

        const handleSelect = (eventKey: string | undefined) => {          
          if (eventKey && eventKey == '1') {
            listPartnersProps.onResetPassword({
                ACCOUNT_EMAIL: rowData.accountEmail,
                USERNAME: rowData.username,
                CREATED_BY: rowData.createdBy
            } as ResetPasswordRequest)
          } else if (eventKey && eventKey == '2') {
            listPartnersProps.onSelectListProductsOption(rowData as PartnerDetails);
          } else if (eventKey && eventKey == '3') {
            listPartnersProps.onSelectListSlogansOption(rowData);
          } else {
            console.log(eventKey);
          }
          
          e.onClose();
        };
        
        return (
          <Popover ref={ref} className={e.className}  full>
            <Dropdown.Menu onSelect={evtKey => handleSelect(evtKey)}>
              <Dropdown.Item eventKey={1}>Reset Password</Dropdown.Item>
              <Dropdown.Item eventKey={2}>List Products</Dropdown.Item>
              <Dropdown.Item eventKey={3}>List Slogans</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
      };
    
    useEffect(() => {
      const interval = setInterval(() => {
        const selectedPartner = dt.getCache("activePartner") as PartnerDetails;
      
        if (selectedPartner) {
          const data = {content: [selectedPartner], pageable: { pageSize: 1, pageNumber: 0}, totalElements: 1} as Partners;
          //console.log(data);
          setFilteredData(data);
        } else {
          setFilteredData(null);
        }       
      }, 500);
      return () => clearInterval(interval);
    }, [dt, getPartners])

    useEffect(() => {        
      const interval = setInterval(() => {
      const reload = dt.getCache("reloadPartnerDetailsTable") as string;          
      if (reload === "true") {
        getPartners();
        dt.setCache("reloadPartnerDetailsTable", "false");
      }    
      }, 500);
      return () => clearInterval(interval);
  }, [dt, getPartners]) 

    useEffect(() => {
      if (pageSize > 1) getPartners();              
    }, [pageNumber, pageSize, getPartners])

    return filteredData ? (
      <Table
            height={500}
            autoHeight={true}            
            rowHeight={45}
            data={filteredData.content}
            >
            <Column width={200} align="left" resizable>
                <HeaderCell>Account Email</HeaderCell>
                <Cell dataKey="accountEmail" style={{fontSize: 12}}/>
            </Column>

            <Column width={120}>
                <HeaderCell>Account Manager</HeaderCell>
                <Cell dataKey="accountManagerNumber" style={{fontSize: 12}}/>
            </Column>

            <Column width={120}>
                <HeaderCell>Account Partner</HeaderCell>
                <Cell dataKey="accountPartnerNumber" style={{fontSize: 12}}/>
            </Column>

            <Column width={120}>
                <HeaderCell>Product Manager</HeaderCell>
                <Cell dataKey="productManagerNumber" style={{fontSize: 12}}/>
            </Column>

            <Column width={120} resizable>
                <HeaderCell>Username</HeaderCell>
                <Cell dataKey="username" style={{fontSize: 12}}/>
            </Column>

            <Column width={100} resizable>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey="createdAt" style={{fontSize: 12}}/>
            </Column>

            <Column width={150} resizable>
                <HeaderCell>Created By</HeaderCell>
                <Cell dataKey="createdBy" style={{fontSize: 12}}/>
            </Column>
            <Column width={70} align="left">
                <HeaderCell style={{fontWeight: "bolder", width: "70px"}}>Actions</HeaderCell>

                <Cell className="link-group" style={{fontWeight: "bolder", display:"flex", alignItems: "center", alignContent: "center"}}>
                    {rowData => <Whisper placement="autoVerticalStart" trigger="click" speaker={(e: any, ref: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null | undefined) => renderMenu(e, ref, rowData as PartnerDetails)}>
                        <IconButton appearance="subtle" icon={<MoreIcon />}/>
                    </Whisper>
                    }
                </Cell>
            </Column>
        </Table>
    ) : (showLoader ? <Loader /> : (
     partners && partners.content?.length > 0 ? 
        (
        <>   
        <Table
            height={800}         
            rowHeight={45}
            data={partners.content}
            >
            <Column width={200} align="left" resizable fixed>
                <HeaderCell>Account Email</HeaderCell>
                <Cell dataKey="accountEmail" style={{fontSize: 12}}/>
            </Column>

            <Column width={120}>
                <HeaderCell>Account Manager</HeaderCell>
                <Cell dataKey="accountManagerNumber" style={{fontSize: 12}}/>
            </Column>

            <Column width={120}>
                <HeaderCell>Account Partner</HeaderCell>
                <Cell dataKey="accountPartnerNumber" style={{fontSize: 12}}/>
            </Column>

            <Column width={120}>
                <HeaderCell>Product Manager</HeaderCell>
                <Cell dataKey="productManagerNumber" style={{fontSize: 12}}/>
            </Column>

            <Column width={120} resizable>
                <HeaderCell>Username</HeaderCell>
                <Cell dataKey="username" style={{fontSize: 12}}/>
            </Column>

            <Column width={100} resizable>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey="createdAt" style={{fontSize: 12}}/>
            </Column>

            <Column width={150} resizable>
                <HeaderCell width={150}>Created By</HeaderCell>
                <Cell dataKey="createdBy" style={{fontSize: 12}}/>
            </Column>
            <Column width={70} align="left">
                <HeaderCell width={70} style={{fontWeight: "bolder", width: "70px"}}>Actions</HeaderCell>

                <Cell className="link-group" style={{fontWeight: "bolder", display:"flex", alignItems: "center", alignContent: "center"}}>
                    {rowData => <Whisper placement="autoVerticalStart" trigger="click" speaker={(e: any, ref: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null | undefined) => renderMenu(e, ref, rowData as PartnerDetails)}>
                        <IconButton appearance="subtle" icon={<MoreIcon />}/>
                    </Whisper>
                    }
                </Cell>
            </Column>
        </Table>
        
        <GenericTablePagination 
            pageChangeHandler={ setPageNumber } 
            pageSizeHandler={setPageSize} 
            totalRows={partners.totalElements}
            pageNumber={pageNumber}
            rowsPerPage={pageSize} />
        </>
        ) : <span>An Error Occurred: No Data Loaded</span>)
    )
}

export default ListPartners