import React, { useState } from "react";
import './../../styles/shared_components_styles/sidebar.css'
import PhonelinkRingRoundedIcon from '@mui/icons-material/PhonelinkRingRounded';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Tooltip from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";

export type SidebarProps = {
  setActiveComponent: (componentName: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({setActiveComponent}) => {
    const [toggleSidebar, setToggleSidebar] = useState<boolean>(false);
    const data = [
        { icon: <AnalyticsIcon />, label: 'Dashboard', component: 'dashboard' },
        { icon: <PhonelinkRingRoundedIcon />, label: 'Bulk Data', component: 'bulkData' },
        { icon: <MusicNoteRoundedIcon />, label: 'Skiza Shortcuts', component: 'skiza' },
      ];

    return (
        <div className={toggleSidebar ? "sidebar collapsed" : "sidebar"}>
            <div className="sidebar-title">
                {toggleSidebar ? 
                    <img src="./../../../rsz_ds-logo-sm.png" alt="Digital Services Logo"/>
                  : <img src="./../../../rsz_logo-color.png" alt="Digital Services Logo"/>}                
            </div>           
            <div className="sidebar-controls" onClick={() => setToggleSidebar(!toggleSidebar)}>
              <hr className="sidebar-title-divider"/>
              <SwapHorizontalCircleRoundedIcon />              
            </div>
            
            { data.map((item) => (
              <Tooltip TransitionComponent={Zoom} title={item.label} key={item.label} disableHoverListener={!toggleSidebar}>
                  <ListItemButton                    
                    sx={{ py: 2, minHeight: 32, color: '#3AA335', flexGrow: "0", justifyContent: "right"}}
                    onClick={() => setActiveComponent(item.component)}
                  >                 
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      {item.icon}
                    </ListItemIcon>
                    { !toggleSidebar ?
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                      /> : null
                    }
                  </ListItemButton>
                </Tooltip>
                ))}
            
        </div>
    )
}

export default Sidebar