import React from "react"
import './../../styles/shared_components_styles/dashboard-root-component.css'
import ProfilesComponent from "./ProfilesComponent"
import BulkDataRootComponent from "../bulk_data_components/BulkDataRootComponent"
import BulkDataCacheProvider from "../bulk_data_components/BulkDataCacheProvider"
import DashboardReports from "../dashboard_reporting_components/DashboardReports"
import SkizaRootComponent from "../skiza_components/SkizaRootComponent"
import { Skeleton } from "@mui/material"

export type DashboardRootComponentProps = {
    componentName: string;
}

const DashboardRootComponent: React.FC<DashboardRootComponentProps> = ({componentName}) => {

    const getComponentToLoad = (name: string) => {
        switch (name) {
            case 'dashboard':
                return <DashboardReports />;
            case 'bulkData':
                return <BulkDataCacheProvider><BulkDataRootComponent /></BulkDataCacheProvider>;
            case 'skiza':
                return <SkizaRootComponent />;
            default:
                return <Skeleton />;
        }
    }
    return (
        <div className="dashboard-root-component">
            <ProfilesComponent />
            <div className="dashboard-content-container">                
                { getComponentToLoad(componentName) }
            </div>            
        </div>
    )
}

export default DashboardRootComponent