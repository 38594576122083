import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import DashboardPage from './pages/DashboardPage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import { useMsal } from '@azure/msal-react'
import { saveRoles } from './utility/appRoles'

const RootComponent: React.FC = () => {
    const { accounts } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        if (accounts && accounts[0]) {
            localStorage.setItem('userEmail', accounts[0].username);
            localStorage.setItem('userName', accounts[0].name ? accounts[0].name : '');
            saveRoles(accounts);
            setIsLoading(false)            
        }        
    }, [accounts])    
    
    return (
        <>
        { isLoading ? <p>Please wait as we sign you in</p> :
            <Router>
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<DashboardPage />} />
                </Routes>
            </Router>
        }   
        </>
    )
}

export default RootComponent
