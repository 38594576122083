import axios from "axios";
import { ErrorDetails } from "../../types/ErrorInfo";

export const getBulkDataAuthentication = async () : Promise<string | null | unknown> => {
    const access_token = localStorage.getItem('bulk_data_access_token');
    const refresh_token = localStorage.getItem('bulk_data_refresh_token');
    const expiry_time = localStorage.getItem('bulk_data_access_token_expires_at');
    if (expiry_time) {
        const expires_at = new Date(expiry_time);
        if (expires_at.getTime() - (new Date).getTime() >= (10 * 60000) && access_token) {
            return Promise.resolve(access_token);
        }

        if (expires_at.getTime() - (new Date).getTime() > 0 &&
           expires_at.getTime() - (new Date).getTime() < (10 * 60000) && refresh_token) {
            return axios.post(`${process.env.REACT_APP_BULK_DATA_AUTH}/api/v1/auth/refresh-token`, {           
                realm: `${process.env.REACT_APP_BULK_DATA_REALM}`,
                client_id: `${process.env.REACT_APP_BULK_DATA_CLIENT_ID}`,
                refresh_token: refresh_token     
             }, {
                headers: {
                    'Authorization': `Basic ${process.env.REACT_APP_BULK_DATA_API_KEY}`,
                    'Content-Type': 'application/json'
                }
             })
            .then(response => {
                if (response.status == 200) {
                    localStorage.setItem('bulk_data_access_token', response.data.access_token);
                    localStorage.setItem('bulk_data_refresh_token', response.data.refresh_token);
                    const seconds = Number(response.data.expires_in);
                    localStorage.setItem('bulk_data_access_token_expires_at', (new Date((new Date(expires_at)).getTime() + seconds * 1000)).toISOString());
                    return Promise.resolve(response.data.access_token as string);
                }                
                Promise.reject({} as ErrorDetails);
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);                
            })
        }
    }
    
    localStorage.removeItem('bulk_data_access_token');
    localStorage.removeItem('bulk_data_refresh_token');
    localStorage.removeItem('bulk_data_access_token_expires_at');
    return axios.post(`${process.env.REACT_APP_BULK_DATA_AUTH}/api/v1/auth/request-token`, {       
        realm: `${process.env.REACT_APP_BULK_DATA_REALM}`,
        client_id: `${process.env.REACT_APP_BULK_DATA_CLIENT_ID}`,
        username: `${process.env.REACT_APP_BULK_DATA_APP_ID}`,
        password: `${process.env.REACT_APP_BULK_DATA_SECRET}`
     }, {
        headers: {
            'Authorization': `Basic ${process.env.REACT_APP_BULK_DATA_API_KEY}`,
            'Content-Type': 'application/json'
        }
     })
    .then(response => {
        if (response.status == 200) {
            localStorage.setItem('bulk_data_access_token', response.data.access_token);
            localStorage.setItem('bulk_data_refresh_token', response.data.refresh_token);
            const seconds = Number(response.data.expires_in);
            localStorage.setItem('bulk_data_access_token_expires_at', (new Date((new Date()).getTime() + seconds * 1000)).toISOString());
            return Promise.resolve(response.data.access_token as string);
        }
        return Promise.reject({} as ErrorDetails);
    })
    .catch(error => {
        console.log(error);
        return Promise.reject(error);             
    })   
}