import Alert from '@mui/material/Alert';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ErrorDetails } from '../../types/ErrorInfo';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ResetPasswordRequest } from '../../types/bulk_data_types/Partners';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';

const ResetPassword:React.FC<ResetPasswordRequest> = (resetPasswordProps: ResetPasswordRequest) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);

    const accountDetails = [{
        id: "reset-password-created-by",
        label: "Created By",
        value: resetPasswordProps.CREATED_BY
    },
    {
        id: "reset-password-username",
        label: "Username",
        value: resetPasswordProps.USERNAME
    }, 
    {
        id: "reset-password-account-email",
        label: "Account Email",
        value: resetPasswordProps.ACCOUNT_EMAIL
    }];

    const resetPassword = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setIsCancelDisabled(true);
        //console.log(process.env.REACT_APP_LDAP_URL);        
        
        getBulkDataAuthentication().then(
            response => {
              const access_token = typeof response === 'string' ? response : null;
              console.log(response);
              if (access_token) {
                axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/passwordreset`, {
                    PasswordResetRequest: {
                        CREATED_BY: resetPasswordProps.CREATED_BY,
                        USERNAME: resetPasswordProps.USERNAME,
                        ACCOUNT_EMAIL: resetPasswordProps.ACCOUNT_EMAIL
                      }
                }, 
                {
                    headers: {
                        'x-source-system': 'bulk-data-portal',
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                }})
                .then(response => {
                    if (response.data.Status == '1000') {
                        resetPasswordProps.setModalOpen(false);
                        resetPasswordProps.setActionSuccessMessage("Password successfully reset.");
                        resetPasswordProps.setPasswordRequest(null);
                    } else {                
                        setErrorMessage(response.data.Desc);
                    }
                  })
                .catch(error => {
                    const errMessage = (error as ErrorDetails).message;
                    setErrorMessage(errMessage == null ? "An error occurred" : errMessage);
                }).finally(
                    () => {
                        setIsLoading(false);
                        setIsCancelDisabled(false);
                    }
                );
              } 
              else {
                setIsLoading(false);
                setIsCancelDisabled(false);
              }
            }
          ).catch(error => {
            console.log(error);
            setIsLoading(false);
            setIsCancelDisabled(false);
          })
    }
    
    const closeModal = () => {
        resetPasswordProps.setModalOpen(false);
        resetPasswordProps.setPasswordRequest(null);
    }

    useEffect(() => {    
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 8000);    
        return () => clearTimeout(timer);
      }, [errorMessage, setErrorMessage])

    return (
        <div className='modal-container'>
            <div className='cp-modal-title'>
                <span>RESET ACCOUNT PASSWORD</span>
                <span>New password will be shared to the Account Email below</span>
            </div>
            <hr className='cp-modal-hr' />
            {errorMessage.length > 0 ?  <Alert severity="warning">{errorMessage}</Alert>: null}
            <form className='modal-form' onSubmit={(e) => resetPassword(e)}>
                <div className='modal-content-form-input'>
                    {accountDetails.map((option) => {
                        return <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            value={option.value}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                            disabled={true}
                        />
                    })}
                </div>
                <hr className='cp-modal-hr' />
                <div className='modal-content-form-submit'>
                    <LoadingButton loading={isLoading} loadingIndicator="RESETTING..." type='submit' variant="outlined" color="success" sx={{width:"200px"}}>
                        RESET
                    </LoadingButton>
                    <Button disabled={isCancelDisabled} variant="outlined" color="error" sx={{width:"200px"}} onClick={() => closeModal()}>
                        CANCEL
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ResetPassword