import React, { LegacyRef, useEffect, useState } from 'react';
import { SelectPicker } from 'rsuite';
import './../../styles/bulk_data_styles/bulk_data_auto_complete.css';
import { useBulkDataCache } from './BulkDataCacheProvider';
import { PartnerDetails, SelectPickerData } from '../../types/bulk_data_types/Partners';
import axios from 'axios';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';

const mountRef = React.createRef();

const BulkDataSearchComponent = () => {
  const dt = useBulkDataCache();
  //const [searchedValue, setSearchedValue] = useState<string>("");
  const [suggestionsData, setSuggestionsData] = useState<SelectPickerData[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [activePartner, setActivePartner] =  useState<SelectPickerData | undefined>(undefined);

  const getSuggestions = (searchValue: string) => {
    setShowLoader(true);
    
    getBulkDataAuthentication().then(
      response => {
        const access_token = typeof response === 'string' ? response : null;
        if (access_token) {
          axios.get(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/listusers?partnerName=${searchValue}`, {
            headers: {
              'Content-Type': 'application/json',
              'accept':'application/json',
              'x-source-system': 'bulk-data-portal',
              'Authorization': `Bearer ${access_token}`
            },
          })
          .then(response => {
            setSuggestionsData((response.data as PartnerDetails[])?.map(item => {
              dt.setCache(item.username, item);
              return ({ label: item.username, value: item.username } as SelectPickerData)
            }));
            setShowLoader(false);
          })
          .catch(error => {
            console.log(error);
            setShowLoader(false);
          });
        } 
        else {
          setShowLoader(false);
        }
      }
    ).catch(error => {
      console.log(error);
      setShowLoader(false);
    })
  }
  
  const handleOnSearch = (value: string) => {    
    if (value && value.length == 1) {
      getSuggestions(value);
    }
 }

 // Shows selected value
 const handleOnChange = (value: string | null) => {
  if (value) {
    dt.setCache("activePartner", dt.getCache(value));
    setActivePartner({ label: value, value: value })
    return;
  }
  dt.setCache("activePartner", null);
  setActivePartner(undefined)
}

useEffect(() => {        
  const interval = setInterval(() => {
    const partner = dt.getCache("activePartner") as PartnerDetails;
    if (partner) {
      if (typeof activePartner === 'undefined') {
        setSuggestionsData([{ label: partner.username, value: partner.username }] as SelectPickerData[])
        setActivePartner({ label: partner.username, value: partner.username });
      }
    } else {
        setActivePartner(undefined);
    }      
    }, 500);
    return () => clearInterval(interval);
  }, [dt, activePartner, setActivePartner])

return (
  <div className='select-picker-container' ref={mountRef as LegacyRef<HTMLDivElement>}>
    { activePartner ? 
    <>
        <SelectPicker 
          container={() => mountRef.current as HTMLElement}
          menuStyle={{ position: 'absolute', zIndex: 20 }}
          id="bulk_data_select_picker"
          label="Username"
          data={suggestionsData}          
          onSearch={(value) => handleOnSearch(value)}
          onChange={(value) => handleOnChange(value)}
          virtualized      
          loading={showLoader}          
          defaultValue={activePartner.value}
          value={activePartner.value}
        />
        </>
      :
      <>      
      <SelectPicker 
        container={() => mountRef.current as HTMLElement}
        menuStyle={{ position: 'absolute', zIndex: 20 }}
        id="bulk_data_select_picker"
        label="Username"        
        data={suggestionsData}
        onSearch={(value) => handleOnSearch(value)}
        onChange={(value) => handleOnChange(value)}
        virtualized      
        loading={showLoader}
        value={null}
        />
        </>
    }    
  </div>
)};

export default BulkDataSearchComponent

