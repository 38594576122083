import React, { useEffect, useState } from "react";
import { UpdateProductProps } from "../../types/bulk_data_types/Products";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { ErrorDetails } from "../../types/ErrorInfo";
import { getBulkDataAuthentication } from "../auth/bulk_data_auth";

const UpdateProduct:React.FC<UpdateProductProps> = (updateProductProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);
    const [username, setUsername] = useState<string>(updateProductProps.product.partnerName);
    const [productName, setProductName] = useState<string>(updateProductProps.product.productName);
    const [topUpAmount, setTopUpAmount] = useState<number>(0);
    const userEmail = localStorage.getItem('userEmail');
    const [updatedBy, setUpdatedBy] = useState<string>(userEmail ? userEmail : '');
    
    const productDetails = [{
        id: "partner-name",
        label: "Parter Username",
        value: username,
        mutable: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUsername(e.target.value)
    },
    {
        id: "product-name",
        label: "Product Name",
        value: productName,
        mutable: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setProductName(e.target.value)
    }, 
    {
        id: "top-up-amount",
        label: "Top Up Units Amount",
        value: topUpAmount.toString(),
        mutable: true,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            isNaN(Number(e.target.value)) ? setErrorMessage("Invalid number on top up amount") : setTopUpAmount(Number(e.target.value));
        }
    },
    {
        id: "updated-by-email",
        label: "Updated By",
        value: updatedBy, //TO DO: use signed in user
        mutable: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUpdatedBy(e.target.value)
    }
];

    const topUpProduct = (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        setIsCancelDisabled(true);

        getBulkDataAuthentication().then(
            response => {
              const access_token = typeof response === 'string' ? response : null;
              console.log(response);
              if (access_token) {
                axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/topupproductrequest`, {
                    TopupProductRequest:{
                        UPDATED_BY: updatedBy,
                        USERNAME: username,
                        PRODUCT_NAME: productName,
                        TOPUP_AMOUNT: topUpAmount,
                        ORDER_NUMBER: null
                    }
                }, 
                {
                    headers: {
                        'x-source-system': 'bulk-data-portal',
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                }})
                  .then(response => {
                    if (response.data.Status == '1000') {
                        updateProductProps.setModalOpen(false);
                        updateProductProps.setActionSuccessMessage("Product successfully updated.");
                    } else {                
                        setErrorMessage(response.data.Desc);
                    }
                  })
                  .catch(error => {
                    const errMessage = (error as ErrorDetails).message;
                    setErrorMessage(errMessage == null ? "An error occurred" : errMessage);
                }).finally(
                    () => {
                        setIsLoading(false);
                        setIsCancelDisabled(false);
                        updateProductProps.setReload(true);
                    }
                );
              } 
              else {
                setIsLoading(false);
                setIsCancelDisabled(false);
              }
            }
          ).catch(error => {
            console.log(error);
            setIsLoading(false);
            setIsCancelDisabled(false);
          })
    }
    
    const closeModal = () => {
        updateProductProps.setModalOpen(false);
    }

    useEffect(() => {    
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 8000);    
        return () => clearTimeout(timer);
      }, [errorMessage, setErrorMessage])

    return (
        <div className='modal-container'>
            <div className='cp-modal-title'>
                <span>TOP UP PRODUCT</span>
            </div>
            <hr className='cp-modal-hr' />
            {errorMessage.length > 0 ?  <Alert severity="warning">{errorMessage}</Alert>: null}
            <form className='modal-form' onSubmit={(e) => topUpProduct(e)}>
                <div className='modal-content-form-input'>
                    {productDetails.map((option) => {
                        return option.id == "top-up-amount" ? 
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            type="number"                            
                            onChange={e => option.onChange(e)}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                        />                     
                        :
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            value={option.value}
                            onChange={e => option.onChange(e)}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                            disabled={!(option.mutable)}
                        />
                    })}
                </div>
                <hr className='cp-modal-hr' />
                <div className='modal-content-form-submit'>
                    <LoadingButton loading={isLoading} loadingIndicator="UPDATING..." type='submit' variant="outlined" color="success" sx={{width:"200px"}}>
                        TOP UP
                    </LoadingButton>
                    <Button disabled={isCancelDisabled} variant="outlined" color="error" sx={{width:"200px"}} onClick={() => closeModal()}>
                        CANCEL
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default UpdateProduct