import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import './../../styles/shared_components_styles/skeletons.css'

const Skeletons: React.FC = () => {
    return (
        <div className='skeletons'>
            <Skeleton variant="text" width={300} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rounded" width={300} height={40} />
            <span>COMING SOON...</span>
            <Skeleton variant="text" width={300} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="rounded" width={300} height={40} />
        </div>
    )
}

export default Skeletons