import React from "react";
import { GenericPaginationOptions } from "../../types/Pagination";
import './../../styles/shared_components_styles/generic-table-pagination.css';
import { Pagination } from 'rsuite';

const GenericTablePagination: React.FC<GenericPaginationOptions> = ({ pageChangeHandler, pageSizeHandler, totalRows, pageNumber, rowsPerPage } : GenericPaginationOptions) => {

    const handlePageChange = (page: number) => {
      pageChangeHandler(page-1);
    }

    const handlePageSizeChange = (pageSize: number) => {
      pageSizeHandler(pageSize);
      pageChangeHandler(0);
    }
    return (
      <div className="pagination">
        <Pagination
            layout={['total', '-', 'pager', '|', 'limit', 'skip']}
            size={'xs'}
            prev={true}
            next={true}
            first={true}
            last={true}
            ellipsis={true}
            boundaryLinks={true}
            total={totalRows}
            limit={rowsPerPage}
            limitOptions={[10]}
            maxButtons={3}
            activePage={pageNumber + 1}
            onChangePage={(pageNumber: number) => handlePageChange(pageNumber)}
            onChangeLimit={(pageSize: number) => handlePageSizeChange(pageSize)}
        />            
      </div>
    )
}

export default GenericTablePagination
