import React from "react";
import './../../styles/shared_components_styles/profiles-component.css'
import Avatar from "@mui/material/Avatar";
import { PositionChildProps } from "rsuite/esm/Picker";
import { Dropdown, Popover, Whisper } from "rsuite";
import { useMsal } from "@azure/msal-react";
import { handleSignOut } from "../../utility/appRoles";

const ProfilesComponent: React.FC = () => {
    const { instance, accounts } = useMsal();
    const userName = localStorage.getItem('userName');
          
    const stringAvatar = (name: string) => {
        return {
          sx: {
            bgcolor: "gray",
            width: "35px",
            height: "35px"
          },
          children: `${name.split(' ')[0][0]}`,
        };
    }

    const renderMenu = (e: PositionChildProps & 
        Pick<React.HTMLAttributes<HTMLElement>, "id" | "onMouseEnter" | "onMouseLeave"> &
         { onClose: (delay?: number | undefined) => void | NodeJS.Timeout; }, 
         ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | 
                null | undefined,
         ) => {

        const handleSelect = (eventKey: string | undefined) => {      
          
          if (eventKey && eventKey == '1') {
            handleSignOut(instance, accounts);
          } else {
            console.log(eventKey);
          }
          
          e.onClose();
        };
        
        return (
          <Popover ref={ref} className={e.className}  full>
            <Dropdown.Menu onSelect={evtKey => handleSelect(evtKey)}>
              <Dropdown.Item eventKey={1}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
    };
    
    return (
        <div className="profiles-component-container">
            <div className="avatar">                
                <Whisper placement="autoVerticalEnd" trigger="click" speaker={(e, ref) => renderMenu(e, ref)}>
                {userName ? <Avatar {...stringAvatar(userName)} /> : <Avatar src="/broken-image.jpg" />}
                </Whisper>                
            </div>
        </div>
    )
}

export default ProfilesComponent
