import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import GenericTablePagination from '../shared_components/GenericTablePagination';
import Loader from '../shared_components/Loader';
import { Table, Popover, Dropdown, Whisper, IconButton } from 'rsuite';
import "./../../styles/bulk_data_styles/list_product.css"
import { ListSlogansProps, SloganDetails, Slogans } from '../../types/bulk_data_types/Slogans';
import MoreIcon from '@rsuite/icons/legacy/More';
import { PositionChildProps } from "rsuite/esm/Picker";
import UpdateSlogan from './UpdateSlogan';
import { useBulkDataCache } from './BulkDataCacheProvider';
import { PartnerDetails } from '../../types/bulk_data_types/Partners';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';

const ListSlogans:React.FC<ListSlogansProps> = (listSlogansProps) => {
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [listSlogansProp] = useState<ListSlogansProps>(listSlogansProps);
    const [reloadTable, setReloadTable] = useState<boolean>(false);
    const [activePartner, setActivePartner] = useState<string | null>();
    const [slogans, setSlogans] = useState<Slogans>({ content: [], pageable: { pageNumber: 0, pageSize: 10}, totalElements: 0});
    const { Column, HeaderCell, Cell } = Table;
    const dt = useBulkDataCache();

    const getSlogans = useCallback(() => {
        setShowLoader(true);
        getBulkDataAuthentication().then(
            response => {
              const access_token = typeof response === 'string' ? response : null;              
              if (access_token) {
                axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/listsmsslogans?page=${pageNumber}&size=${pageSize}`, {
                    ListSMSSloganRequest: {
                        USERNAME: activePartner,
                    }
                }, 
                {
                    headers: {
                        'x-source-system': 'bulk-data-portal',
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                }})
                .then(response => { 
                    setSlogans(response.data as Slogans);
                    setShowLoader(false);
                })
                .catch(error => {
                    console.log(error);
                    setShowLoader(false);
                });
              } 
              else {
                setShowLoader(false);
              }
            }
          ).catch(error => {
            console.log(error);
            setShowLoader(false);
          })
    }, [activePartner, pageNumber, pageSize])

    useEffect(() => {        
        const interval = setInterval(() => {
        const partner = dt.getCache("activePartner") as PartnerDetails;            
        if (partner) {
            if (partner.username !== activePartner) setPageNumber(0);
            setActivePartner(partner.username);
        } else {
            setPageNumber(0);
            setActivePartner(null);
        }      
        }, 500);
        return () => clearInterval(interval);
      }, [dt, activePartner])

    useEffect(() => {
        if (activePartner) {            
            getSlogans();
        }
    }, [activePartner, setActivePartner, getSlogans, pageNumber, pageSize])

    useEffect(() => {
        if (reloadTable) {
            getSlogans();
            setReloadTable(false);
        }
    }, [reloadTable, getSlogans])

    useEffect(() => {        
        const interval = setInterval(() => {
        const reload = dt.getCache("reloadSlogansList") as string;          
        if (reload === "true") {
            getSlogans();
            dt.setCache("reloadSlogansList", "false");
        }    
        }, 500);
        return () => clearInterval(interval);
    }, [dt, getSlogans])   
    
    const renderMenu = (e: PositionChildProps & 
        Pick<React.HTMLAttributes<HTMLElement>, "id" | "onMouseEnter" | "onMouseLeave"> &
         { onClose: (delay?: number | undefined) => void | NodeJS.Timeout; }, 
         ref: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | 
                null | undefined,
         rowData: SloganDetails) => {

        const handleSelect = (eventKey: string | undefined) => {       
          console.log(eventKey);
          if (eventKey && eventKey == '1') {
            listSlogansProp.setModalOpen(true);
            listSlogansProp.showActionOnModal(<UpdateSlogan 
                SLOGAN_ID={rowData.id} 
                SLOGAN={rowData.slogan} 
                PARTNER_NAME={rowData.partnerName} 
                IS_ACTIVE={rowData.isActive}
                setReload={setReloadTable}
                setModalOpen={listSlogansProp.setModalOpen} 
                setActionSuccessMessage={listSlogansProp.setActionSuccessMessage} />)
          } else {
            console.log(eventKey);
          }          
          e.onClose();
        };
        
        return (
          <Popover ref={ref} className={e.className}  full>
            <Dropdown.Menu onSelect={evtKey => handleSelect(evtKey)}>
              <Dropdown.Item eventKey={1}>Update Slogan</Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
    };

    return (activePartner ? (showLoader ? <Loader /> : (
        slogans && slogans.content?.length > 0 ? 
           (
           <>
           <Table
                height={500}
                autoHeight={true}
                rowHeight={45}
                data={slogans.content}
                >
                <Column width={40} align="left" resizable>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id" style={{fontSize: 12}}/>
                </Column>

                <Column width={120} resizable>
                    <HeaderCell>Partner Name</HeaderCell>
                    <Cell dataKey="partnerName" style={{fontSize: 12}}/>
                </Column>

                <Column width={120} resizable>
                    <HeaderCell>Slogan</HeaderCell>
                    <Cell dataKey="slogan" style={{fontSize: 12}}/>
                </Column>

                <Column width={120}>
                    <HeaderCell>isActive</HeaderCell>
                    <Cell style={{fontSize: 12}}>{rowData => rowData.isActive == '1' ? "true" : "false"}</Cell>
                </Column>

                <Column width={100} resizable>
                    <HeaderCell>Created At</HeaderCell>
                    <Cell dataKey="createdAt" style={{fontSize: 12}}/>
                </Column>

                <Column width={150} resizable>
                    <HeaderCell>Created By</HeaderCell>
                    <Cell dataKey="createdBy" style={{fontSize: 12}}/>
                </Column>
                <Column flexGrow={1} align="left">
                    <HeaderCell style={{fontWeight: "bolder", width: "100px"}}>Actions</HeaderCell>

                    <Cell className="link-group" style={{fontWeight: "bolder", display:"flex", alignItems: "center", alignContent: "center"}}>
                        {rowData => <Whisper placement="autoVerticalStart" trigger="click" speaker={(e, ref) => renderMenu(e, ref, rowData as SloganDetails)}>
                            <IconButton appearance="subtle" icon={<MoreIcon />}/>
                        </Whisper>
                        }
                    </Cell>
                </Column>
            </Table>
           
           { slogans.totalElements > pageSize ? (
           <GenericTablePagination 
               pageChangeHandler={ setPageNumber } 
               pageSizeHandler={setPageSize} 
               totalRows={slogans.totalElements}
               pageNumber={pageNumber}
               rowsPerPage={pageSize} />) : null
           }
           </>
           ) : (slogans && slogans.content?.length == 0 ? <span>Partner has no configured slogans</span> : <span>An Error Occurred: No Data Loaded</span>)
       )): <span>Select a partner to view partner specific slogans</span>)
}

export default ListSlogans