import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import { persistor, store } from './store/reducers/store'
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

const App: React.FC = () => {

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
           <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RootComponent />
            </PersistGate>
          </Provider> 
        </MsalAuthenticationTemplate>      
    )
}

export default App
