import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// Configuration object constructed.
const config = {
    auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
        authority: `${process.env.REACT_APP_AUTHORITY}`,
        knownAuthorities: [],
        cloudDiscoveryMetadata: "",
        redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
        postLogoutRedirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
        navigateToLoginRequestUrl: true,
        clientCapabilities: ["CP1"]
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: false
    },
    system: {
        loggerOptions: {
            
            // logLevel: msal.LogLevel.Verbose,
            // loggerCallback: (level: any, message: any, containsPii: any) => {
            //     if (containsPii) {
            //         return;
            //     }
            //     switch (level) {
            //         case msal.LogLevel.Error:
            //             console.error(message);
            //             return;
            //         case msal.LogLevel.Info:
            //             console.info(message);
            //             return;
            //         case msal.LogLevel.Verbose:
            //             console.debug(message);
            //             return;
            //         case msal.LogLevel.Warning:
            //             console.warn(message);
            //             return;
            //     }
            // },
            // piiLoggingEnabled: false
        },
    },
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);
const root = createRoot(document.getElementById('root')!) // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <MsalProvider instance={publicClientApplication}>
            <App />
        </ MsalProvider>
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()