import React, { useEffect, useState } from 'react';
import { UpdateSloganRequest } from '../../types/bulk_data_types/Slogans';
import axios from 'axios';
import { ErrorDetails } from '../../types/ErrorInfo';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';

const UpdateSlogan:React.FC<UpdateSloganRequest> = (updateSloganRequest) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);
    const [sloganId, setSloganId] = useState<string>(updateSloganRequest.SLOGAN_ID.toString());
    const [sloganText, setSloganText] = useState<string>(updateSloganRequest.SLOGAN);
    const [partnerName, setPartnerName] = useState<string>(updateSloganRequest.PARTNER_NAME);
    const [isSloganActive, setIsSloganActive] = useState<boolean>(updateSloganRequest.IS_ACTIVE == "true");
    const userEmail = localStorage.getItem('userEmail');
    const [updatedBy, setUpdatedBy] = useState<string>(userEmail ? userEmail : '');
    
    const sloganDetails = [{
        id: "slogan-id",
        label: "Slogan Id",
        value: sloganId,
        mutable: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSloganId(e.target.value)
    },
    {
        id: "slogan-text",
        label: "Slogan",
        value: sloganText,
        mutable: true,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSloganText(e.target.value)
    }, 
    {
        id: "slogan-partner-name",
        label: "Partner Name",
        value: partnerName,
        mutable: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPartnerName(e.target.value)
    },
    {
        id: "is-slogan-active",
        label: "Active",
        value: isSloganActive ? "true" : "false",
        mutable: true,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setIsSloganActive(e.target.value == "true")
    },
    {
        id: "updated-by-email",
        label: "Updated By",
        value: updatedBy, //TO DO: use signed in user
        mutable: false,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setUpdatedBy(e.target.value)
    }
];

    const updateSlogan = (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        setIsCancelDisabled(true);
        
        getBulkDataAuthentication().then(
            response => {
              const access_token = typeof response === 'string' ? response : null;
              console.log(response);
              if (access_token) {
                axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/updatesmsslogan`, {
                    UpdateSMSSloganRequest:{
                        UPDATED_BY: updatedBy,
                        SLOGAN_ID: sloganId,
                        SLOGAN: sloganText,
                        PARTNER_NAME: partnerName,
                        IS_ACTIVE: isSloganActive
                    }
                }, 
                {
                    headers: {
                        'x-source-system': 'bulk-data-portal',
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                }})
                .then(response => {
                    if (response.data.Status == '1000') {
                        updateSloganRequest.setModalOpen(false);
                        updateSloganRequest.setActionSuccessMessage("Slogan successfully updated.");
                    } else {                
                        setErrorMessage(response.data.Desc);
                    }
                  })
                .catch(error => {
                    const errMessage = (error as ErrorDetails).message;
                    setErrorMessage(errMessage == null ? "An error occurred" : errMessage);
                }).finally(
                    () => {
                        setIsLoading(false);
                        setIsCancelDisabled(false);
                        updateSloganRequest.setReload(true);
                    }
                );
              } 
              else {
                setIsLoading(false);
                setIsCancelDisabled(false);
              }
            }
          ).catch(error => {
            console.log(error);
            setIsLoading(false);
            setIsCancelDisabled(false);
          })
    }
    
    const closeModal = () => {
        updateSloganRequest.setModalOpen(false);
    }

    useEffect(() => {    
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 8000);    
        return () => clearTimeout(timer);
      }, [errorMessage, setErrorMessage])

    return (
        <div className='modal-container'>
            <div className='cp-modal-title'>
                <span>UPDATE SMS SLOGAN</span>
            </div>
            <hr className='cp-modal-hr' />
            {errorMessage.length > 0 ?  <Alert severity="warning">{errorMessage}</Alert>: null}
            <form className='modal-form' onSubmit={(e) => updateSlogan(e)}>
                <div className='modal-content-form-input'>
                    {sloganDetails.map((option) => {
                        return option.id == "is-slogan-active" ? 
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            select                            
                            onChange={e => option.onChange(e)}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                        >
                        {["true", "false"].map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                        </TextField>                        
                        :
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            value={option.value}
                            onChange={e => option.onChange(e)}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                            disabled={!(option.mutable)}
                        />
                    })}
                </div>
                <hr className='cp-modal-hr' />
                <div className='modal-content-form-submit'>
                    <LoadingButton loading={isLoading} loadingIndicator="UPDATING..." type='submit' variant="outlined" color="success" sx={{width:"200px"}}>
                        UPDATE
                    </LoadingButton>
                    <Button disabled={isCancelDisabled} variant="outlined" color="error" sx={{width:"200px"}} onClick={() => closeModal()}>
                        CANCEL
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default UpdateSlogan