import axios from "axios";
import { ErrorDetails } from "../../types/ErrorInfo";

export const getSkizaAuthentication = async () : Promise<string | null | unknown> => {
    const access_token = localStorage.getItem('skiza_access_token');
    const expiry_time = localStorage.getItem('skiza_access_token_expires_at');
    if (expiry_time) {
        const expires_at = new Date(expiry_time);
        if (expires_at.getTime() - (new Date).getTime() >= (10 * 60000) && access_token) {
            return Promise.resolve(access_token);
        }
    }
    
    
    localStorage.removeItem('skiza_access_token');
    localStorage.removeItem('skiza_access_token_expires_at');
    return axios.post(`${process.env.REACT_APP_SKIZA_API_URL}/api/v1/users/signin`, {
        username: `${process.env.REACT_APP_SKIZA_APP_ID}`,
        password: `${process.env.REACT_APP_SKIZA_SECRET}`
     }, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
     })
    .then(response => {
        if (response.status == 200) {
            localStorage.setItem('skiza_access_token', response.data.body);
            const seconds = Number(getExpiresAt(response.data.body));
            localStorage.setItem('skiza_access_token_expires_at', (new Date(seconds * 1000)).toISOString());
            return Promise.resolve(response.data.body as string);
        }
        return Promise.reject({} as ErrorDetails);
    })
    .catch(error => {
        console.log(error);
        return Promise.reject(error);             
    })   
}

const getExpiresAt = (token: string) => Number(JSON.parse(atob(token.split('.')[1])).exp);