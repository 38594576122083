import React, { useEffect, useState } from 'react';
import { AddSloganModalProps } from '../../types/bulk_data_types/Slogans';
import axios from 'axios';
import { ErrorDetails } from '../../types/ErrorInfo';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { getBulkDataAuthentication } from '../auth/bulk_data_auth';
import { useBulkDataCache } from './BulkDataCacheProvider';
import { PartnerDetails } from '../../types/bulk_data_types/Partners';

const CreateSlogan:React.FC<AddSloganModalProps> = (addSloganProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState<boolean>(false);
    const [sloganText, setSloganText] = useState<string>('');
    const [isSloganActive, setIsSloganActive] = useState<boolean>(true);
    const userEmail = localStorage.getItem('userEmail');
    const [createdBy] = useState<string>(userEmail ? userEmail : '');
    const dt = useBulkDataCache();
    const [activePartner] = useState<PartnerDetails | null>((dt.getCache("activePartner") as PartnerDetails) ? 
            (dt.getCache("activePartner") as PartnerDetails) : null);

    const sloganDetails = [
    {
        id: "slogan-text",
        label: "Slogan",
        mutable: true,
        defaultValue: sloganText,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSloganText(e.target.value)
    }, 
    {
        id: "slogan-partner-name",
        label: "Partner Username",
        mutable: false,
        defaultValue: activePartner?.username,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => console.log(e.target.value)
    },
    {
        id: "is-slogan-active",
        label: "Active",
        mutable: true,
        defaultValue: isSloganActive,
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setIsSloganActive(e.target.value == "true")
    }
];

    const createSlogan = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setIsCancelDisabled(true);

        getBulkDataAuthentication().then(
            response => {
                const access_token = typeof response === 'string' ? response : null;
                if (access_token) {
                    axios.post(`${process.env.REACT_APP_BULK_DATA_RESOURCES_API}/v1/createsmsslogan`, {
                        CreateSMSSloganRequest:{
                            CREATED_BY: createdBy,
                            SLOGAN: sloganText,
                            PARTNER_NAME: activePartner?.username,
                            IS_ACTIVE: isSloganActive
                        }
                    },{
                        headers: {
                            'x-source-system': 'bulk-data-portal',
                            'Authorization': `Bearer ${access_token}`,
                            'Content-Type': 'application/json'
                        }})
                    .then(response => {
                        if (response.data.Status == '1000') {
                            addSloganProps.setModalOpen(false);
                            addSloganProps.setActionSuccessMessage("Slogan successfully added.");
                            dt.setCache("reloadSlogansList", "true");
                        } else {                
                            setErrorMessage(response.data.Desc);
                        }
                      })
                    .catch(error => {
                        const errMessage = (error as ErrorDetails).message;
                        setErrorMessage(errMessage == null ? "An error occurred" : errMessage);
                    }).finally(
                        () => {
                            setIsLoading(false);
                            setIsCancelDisabled(false);
                        }
                    );
                } else {                    
                    setIsLoading(false);
                    setIsCancelDisabled(false);
                }
            }
            ).catch(error => {
                console.log(error);
                setIsLoading(false);
                setIsCancelDisabled(false);
            })
    }
    
    const closeModal = () => {
        addSloganProps.setModalOpen(false);
    }

    useEffect(() => {    
        const timer = setTimeout(() => {
            setErrorMessage("");
        }, 8000);    
        return () => clearTimeout(timer);
      }, [errorMessage, setErrorMessage])

    return (
        <div className='modal-container'>
            <div className='cp-modal-title'>
                <span>CREATE SMS SLOGAN</span>
            </div>
            <hr className='cp-modal-hr' />
            {errorMessage.length > 0 ?  <Alert severity="warning">{errorMessage}</Alert>: null}
            <form className='modal-form' onSubmit={(e) => createSlogan(e)}>
                <div className='modal-content-form-input'>
                    {sloganDetails.map((option) => {
                        return option.id == "is-slogan-active" ? 
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            select
                            onChange={e => option.onChange(e)}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            defaultValue={option.defaultValue}
                            color="success"
                            size="small"
                            required
                        >
                        {["true", "false"].map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                        </TextField>                        
                        :
                        <TextField
                            key={option.id}
                            label={option.label}
                            id={option.id}
                            onChange={e => option.onChange(e)}
                            sx={{ marginBottom: '20px' }}
                            fullWidth
                            color="success"
                            size="small"
                            required
                            defaultValue={option.defaultValue}
                            disabled={!(option.mutable)}
                        />
                    })}
                </div>
                <hr className='cp-modal-hr' />
                <div className='modal-content-form-submit'>
                    <LoadingButton loading={isLoading} loadingIndicator="CREATING..." type='submit' variant="outlined" color="success" sx={{width:"200px"}}>
                        CREATE
                    </LoadingButton>
                    <Button disabled={isCancelDisabled} variant="outlined" color="error" sx={{width:"200px"}} onClick={() => closeModal()}>
                        CANCEL
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default CreateSlogan